import {FC, useContext, useEffect, useRef, useState} from "react";

import { observer } from "mobx-react-lite";
import { getLeague } from "../../../helpers/getLeague";

const PlanetIcon = observer(({coins}) => {
        return (
            <>
                <img src={getLeague(coins).img}></img>
            </>
        )
});

export default PlanetIcon
