import react, { useContext } from 'react'
import cls from './daylyHearts.module.css'
import { Context } from '../../../..';
import { setClaimDays } from '../../../../http/userAPI';
import { observer } from 'mobx-react-lite';

const DaylyHearts = observer(({setDays, userSet}) => {

    const {user} = useContext(Context);
    

    async function parseHearts() {
        const newHearts =  user.daysCount > 3 ? 3 : user.daysCount;
        user.setHeart(user.heart + newHearts)
        user.setDaysCount(0);
        await setClaimDays(user.id, newHearts);
    }
    return (
        <div className={cls.main}>
            <p>{user.daysCount} {user.daysCount === 1 ? 'DAY' : 'DAYS'} CHECK-IN</p>
            <p>TAKE YOUR REWARD!</p>
            <div className={cls.hearts}>
                <img src='./assets/images/Heart2.png' alt='heart'></img>
                <img style={{opacity: `${user.daysCount > 1 ? 1 : 0.3}`}} src='./assets/images/Heart2.png' alt='heart'></img>
                <img style={{opacity: `${user.daysCount > 2 ? 1 : 0.3}`}} src='./assets/images/Heart2.png' alt='heart'></img>
            </div>
            <button onClick={() => parseHearts()} className={cls.button}>GOT IT</button>
        </div>
    )
});

export default DaylyHearts;

//user.daysCount