 import {FC, useContext, useState} from "react";

import styles from "./styles.module.css";
import InvitesList from "../../compotents/Pages/Invites/InvitesList/InvitesList.jsx";
import InvitesModal from "../../compotents/Pages/Invites/InvitesModal/InvitesModal.jsx";
import { Context } from "../../index.js";
import { observer } from "mobx-react-lite";
import { clearClaimRefferal } from "../../http/userAPI.js";
import { Link } from "react-router-dom";
import PlanetIcon from "../../compotents/Pages/MainLayout/Icon/Icon.jsx";
import { formatedCoins } from "../../compotents/helpers/coinFormated.js";
import InvitesId from "../../compotents/Pages/Invites/InvitesId/InvitesId.jsx";
const tg = window?.Telegram?.WebApp;

export const Invites = observer(() => {
    const name = 'Vladimir Dzen'
    const image = './assets/images/userr.png';
    const {user} = useContext(Context);

    const [modal, setModal] = useState(false);
    
    function setModalOpen() {
        setModal(prevModal => !prevModal);
    }

    async function clearClaim() {
        try {
            const answer = await clearClaimRefferal(user.id, user.claimcoins);
            if(answer) {
                user.setCoins(user.coins + user.claimcoins);
                user.setClaimcoins(0);
                
                console.log(user.coins)
            } 
        }
        catch(e) {
            console.log(e);
        }
    }
    return (
        <div className={styles.container}>
            <Link to={'/leaders'} className={styles.userHeader}>
                <PlanetIcon coins={user.coins}></PlanetIcon>
                <span>{window?.Telegram?.WebApp?.initDataUnsafe?.user?.username || 'John'}</span>
            </Link>
            
            <InvitesId tg={tg}></InvitesId>

            <div className={styles.buttonLink}>
                <button className={styles.button} onClick={() => {setModalOpen()}}>
                    Your link
                </button>
            </div>
            {/* <p className={styles.text}>Score 10% from friends<br></br>
Get +1 heart & 10000 coins for each friend
Get +2 heart & 25 000 coins for each friend<br></br> with TG Premium</p> */}
            <img className={styles.text} alt="haha" src="./assets/images/text.png"></img>
            {
                user.claimcoins > 0 ? 
                <div className={styles.claimButton} onClick={clearClaim}>
                    Claim <img className={styles.smallCoin} src="./assets/sprites/Coin2.png" alt="coin"></img> {formatedCoins(user.claimcoins || 0)}
                </div> :
                <button className={styles.rewardsButton}>
                    Claim friend's reward
                </button>
                
            }
            <InvitesList></InvitesList>
            {/* casha lox */}
            <InvitesModal visible={modal} setVisible={setModalOpen}></InvitesModal>
        </div>
    );
})

