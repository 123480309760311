import { FC, useState } from "react";

import styles from "./styles.module.css";
import LeagueList from "../../compotents/Pages/league/LeagueList/LeagueList";
import { LeagueSwiper } from "../../compotents/Pages/league/LeagueSwiper/LeagueSwiper";

export const Leaders = () => {
    const [currentLeguage, setCurrentLeguage] = useState(1);

    const goBack = () => {
        window.history.back();
      };

    return (
        <div className={styles.main}>
            <h1 className={styles.title}>
                leaderboard
            </h1>
            <button className={styles.arrow} onClick={goBack}>
                <img alt="arrow" src="./assets/images/backarrow.png" ></img>
            </button>

            {/* Убрали CarouselProvider, так как swiper не требует обертки */}
            <LeagueSwiper setCurrentLeguage={setCurrentLeguage} />
            <h3 className={styles.title}>
                league score
            </h3>
            <LeagueList currentLeguage={currentLeguage} />
        </div>
    );
};

export default Leaders;
