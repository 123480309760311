import React, { useRef, useState } from 'react';
import './style.css';
import GameState from '../../../game/state/gameManager';

const Cube = ({ setIsRoll, setCoinsShowing,setIsAnim, setCoinsCount }) => {
  const cubeRef = useRef(null);
  const [disabled, setDisabled] = useState(false);

  const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  const rollCube = () => {
    if (disabled) return;
    setDisabled(true);

    const cube = cubeRef.current;

    const finalFace = getRandomInt(1, 6);
    let finalRotateX = 0, finalRotateY = 0, finalRotateZ = 0;
    
   
    let varCoins;
    //GameState.coins = GameState.coins * finalFace;
    console.log(finalFace);
    switch (finalFace) {
      case 1: 
        GameState.setCoins(GameState.getCoins() * finalFace);
        setCoinsCount(10);
        finalRotateX = 0; varCoins = finalFace; finalRotateY = 0; finalRotateZ = 0; break;
      case 2: 
      GameState.setCoins(GameState.getCoins() * 3);
      setCoinsCount(12)
        finalRotateX = 0; varCoins = 3; finalRotateY = 180; finalRotateZ = 180; break;
      case 3:
        GameState.setCoins(GameState.getCoins() * finalFace);
        setCoinsCount(15)
        finalRotateX = 0; varCoins = finalFace; finalRotateY = -90; finalRotateZ = 0; break;
      case 4: 
      GameState.setCoins(GameState.getCoins() * finalFace);
      setCoinsCount(17)
        finalRotateX = 0; varCoins = finalFace; finalRotateY = 90; finalRotateZ = 0; break;
      case 5: 
      GameState.setCoins(GameState.getCoins() * finalFace);
      setCoinsCount(20)
        finalRotateX = -90; varCoins = finalFace; finalRotateY = 0; finalRotateZ = 0; break;
      case 6: 
      GameState.setCoins(GameState.getCoins() * finalFace);
      setCoinsCount(22)
        finalRotateX = 90; varCoins = finalFace; finalRotateY = 0; finalRotateZ = 0; break;
      default:
        break;
    }

    
    const animCoins = GameState.coins * (--varCoins);
    setTimeout(() => {
      const lastPart = animCoins % 10
      for(let i = 0; i < animCoins - 10; i+=10) {
        setTimeout(() => {
          setCoinsShowing(p => p+10);
        }, i * 5 * (0.1/finalFace))
      }
      for(let i = 0; i < finalFace * 3; i++) {
        setTimeout(() => {
          window?.Telegram?.WebApp?.HapticFeedback.impactOccurred('medium');
          
        }, i * 100)
      }
      
      setCoinsShowing(p => p + lastPart)
    }, 2000)
    setTimeout(() => {
      setIsAnim(true);
    }, 2000)
    

    const randomRotateX = 1800; // 5 full rotations
    const randomRotateY = 1800;
    const randomRotateZ = 1800;

    const totalRotateX = randomRotateX + finalRotateX;
    const totalRotateY = randomRotateY + finalRotateY;
    const totalRotateZ = randomRotateZ + finalRotateZ;

    cube.style.transition = 'transform 2s cubic-bezier(0.25, 1.0, 0.5, 1.0)';
    cube.style.transform = `rotateX(${totalRotateX}deg) rotateY(${totalRotateY}deg) rotateZ(${totalRotateZ}deg)`;

    setTimeout(() => {
      cube.style.transition = 'none';
      cube.style.transform = `rotateX(${finalRotateX}deg) rotateY(${finalRotateY}deg) rotateZ(${finalRotateZ}deg)`;
      

      setTimeout(() => {
        setIsRoll(false);
        setDisabled(false);
        setIsAnim(false);
        GameState.isRoll = false
      }, 2000);
    }, 2000);
  };

  return (
    <div className="scene">
      <div className={`cube ${disabled ? 'cube--disabled' : ''}`} ref={cubeRef} onClick={rollCube}>
        <div className="cube__face cube__face--front face-1">
          <div className="dot"></div>
        </div>
        <div className="cube__face cube__face--back face-2">
          <div className="dot"></div><div className="dot"></div>
          <div className="dot"></div><div className="dot"></div>
        </div>
        <div className="cube__face cube__face--right face-3">
          <div className="dot"></div><div className="dot"></div><div className="dot"></div>
        </div>
        <div className="cube__face cube__face--left face-4">
          <div className="dot"></div><div className="dot"></div><div className="dot"></div><div className="dot"></div>
        </div>
        <div className="cube__face cube__face--top face-5">
          <div className="dot"></div><div className="dot"></div><div className="dot"></div><div className="dot"></div><div className="dot"></div>
        </div>
        <div className="cube__face cube__face--bottom face-6">
          <div className="dot"></div><div className="dot"></div><div className="dot"></div><div className="dot"></div><div className="dot"></div><div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Cube;
