import {FC} from "react";

import styles from "./styles.module.css";
import { formatedCoins } from "../../../helpers/coinFormated";



export const LeagueCard = ({position, nickname, score, active = false}) => {
    const rootCls = [styles.card];
    if(active) {
        rootCls.push(styles.active);
    }
    return (
        <div className={rootCls.join(' ')}>
            <div className={styles.label}>
                <span className={styles.positionMarker}>
                    {position}
                </span>
                <span className={styles.nickname}>
                    {nickname}
                </span>
            </div>
            <span className={styles.score}>
                {formatedCoins(score)}<img alt="coin" className={styles.coin} src="./assets/images/Coin2.png"></img>
            </span>
        </div>
    );
}

