import React, { useState, useEffect } from 'react';
import { TonConnectButton, useTonWallet, TonConnect } from '@tonconnect/ui-react'; 
import styles from './Wallet.module.css';

const WalletComponent = () => {
    const [wallet, setWallet] = useState(null); 
    const walletVis = useTonWallet(); // Получаем данные кошелька с помощью хука

    useEffect(() => {
        if (walletVis?.account) {
            setWallet(walletVis);  // Сохраняем данные кошелька при подключении
            console.log('Кошелек подключен:', walletVis.account);
        }
    }, [walletVis]);  // Этот эффект срабатывает при изменении состояния walletVis

    return (
        <div className={styles.wallet}>
            {!wallet ? (
                <>
                    <img src="./assets/sprites/wallet.png" alt="wallet icon" />
                    <span>Connect wallet</span>
                    <TonConnectButton className={styles.tonConnect} />  {/* Упрощенный компонент подключения */}
                </>
            ) : (
                <div className={styles.connectedWallet}>
                    <span><img className={styles.ton} alt='ton' src='./assets/images/ton.png'></img>{wallet.account.address.slice(0, 3) + '...' + wallet.account.address.slice(-4, -1)}</span> {/* Отображаем адрес кошелька */}
                </div>
            )}
        </div>
    );
};

export default WalletComponent;
