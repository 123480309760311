import react, { useState } from 'react'
import styles from './InvitesId.module.css'

const InvitesId = ({tg}) => {
    const [isCopy, setIsCopy] = useState(false);

    function Copy() {
        setIsCopy(true);
        setTimeout(() => {
            setIsCopy(false);
        }, 1000)
        navigator.clipboard.writeText(tg?.initDataUnsafe?.user?.id).then(() => {
         
        }, (err) => {
          console.log('Ошибка при копировании ссылки: ' + err);
        });
    }
    
    return (
       <>
       {
        !isCopy ?
        <button className={styles.idUser} onClick={() => {Copy()}}>ID: {tg?.initDataUnsafe?.user?.id | '0'} <img className={styles.copyImage} src="./assets/images/copy.png"></img></button>
        :
        <span className={styles.idUser}>Coped</span>
       }
       </>
    )
}

export default InvitesId;