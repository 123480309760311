import { FC, useContext, useEffect, useState } from "react";

import styles from "./styles.module.css";
import { LeagueCard } from "../LeagueCard/index.js";
import { getLeguage, leaders } from "../../../../http/userAPI.js";
import { Context } from "../../../../index.js";

export const LeagueList = ({currentLeguage}) => {
    const [users, setUsers] = useState([]);
    const {user} = useContext(Context);

    let arr = [];

    const fetchLeaders = async (leguage) => {
        try {
            const usersData = await getLeguage(leguage);
            console.log(usersData)
         
            setUsers(usersData.data); 
            
        } catch (e) {
            console.log(e); 
        }
    };

    useEffect(() => {
        fetchLeaders(1);
        fetchLeaders(); 
        arr = users;
    }, []); 

    useEffect(() => {
        fetchLeaders(currentLeguage);
    }, [currentLeguage])

    return (
        <div className={styles.container}>
            
            <div className={styles.list}>
                {users.map((el, idx) => (
                    <LeagueCard key={idx} position={idx + 1} nickname={el.username} score={el.coins} active={user.id === el.id ? true : false} />
                ))}
            </div>
            <div className={styles.active}>
                <LeagueCard active={true} className={styles.title} key={'you'} position={1000} nickname="TEST" score={1000} />
            </div>
        </div>
    );
};

export default LeagueList;
