import { FC, useContext, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import TasksItem from "../TaskItem/TaskItem";
import { getTasks } from "../../../../http/userAPI";
import { Context } from "../../../..";
const tg = window?.Telegram?.WebApp;

const TasksList = () => {
    const {user} = useContext(Context);
    const [tasks, setTasks] = useState(
        [
       
    ]
    ) 
    const fetchTasksRef = useRef(null);

    async function fetchTasks() {
        fetchTasksRef.current = await getTasks(tg?.initDataUnsafe?.user?.id);
        //fetchTasksRef.current = await getTasks(user?.id);
        const updatedTasks = fetchTasksRef.current.data.map((el) => ({
            title: el.Task.description,
            prise: el.Task.reward,
            link: el.Task.link,
            status: el.status === 'pending' ? 1 : el.status === 'prise' ? 0 : 2,
            id: el.taskId,
        }));

        // Обновляем состояние с помощью функции
        setTasks(prevTasks => [...prevTasks, ...updatedTasks]);
    }

    useEffect(() => {
        fetchTasks();
    }, [])

    // Сортируем заказы по статусу
    const sortedTasks = tasks.sort((a, b) => a.status - b.status);

    console.log(sortedTasks)

    return (
        <div className={styles.main}> 
            <ul className={styles.list}>
                {sortedTasks.map((el) => {
                    return (
                        <TasksItem 
                            key={el.title} 

                            action={el.action} 
                            exercise={el.title} 
                            link={el.link} 
                            statusTask={el.status} 
                            prise={el.prise}
                            id={el.id}
                         
                        ></TasksItem>
                    )
                })}
            </ul>
        </div>
    );
}

export default TasksList;
