
import { AliveScope } from 'react-activation';
import './App.css';
import AppRouter from './pages/AppRouter';
import Home from './pages/Home/Home';
import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
const tg = window?.Telegram?.WebApp;

function App() {

  if (tg) {
      tg.expand();
     
  }

  return (
    <TonConnectUIProvider manifestUrl="https://sphere.kadystest.site/tonconnect-manifest.json">
        <AliveScope>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
        </AliveScope>
    </TonConnectUIProvider>
  );
}

export default App;
