import { FC, useContext, useState } from "react";
import styles from "./styles.module.css";
import { Context } from "../../../..";
import { formatedCoins } from "../../../helpers/coinFormated";


const LeguageBanner = ({maxCount, minCount, color, text}) => { 
    const {user} = useContext(Context);
    const [coins, setCoins] = useState(user.coins - minCount < 0 ? 0 : user.coins - minCount);

   

    return (
        <div className={styles.main}>
           
            {
                user.coins >= minCount ?
                <>
                    {user.coins >= maxCount ?
                     <span> <span style={{color:color}}>{text} league</span> </span>
                     :
                     <span>You’re in the <span style={{color:color}}>{text} league</span> </span>
                    }
                     <span className={styles.coins}>{formatedCoins(maxCount < user.coins ? maxCount : user.coins)}/{formatedCoins(maxCount)}</span>
                     <div
                        className={styles.progressLine}
                        style={{
                            background: `linear-gradient(90deg, rgba(0,255,96,1) ${Math.min((coins / maxCount) * 100)}%, rgba(217,217,217,1)  ${Math.min((coins / maxCount) * 100)}%)`
                        }}
                    ></div>
                </>
                
                :
                <>
                    <span style={{color:color}}>{text} league</span>
                    <img className='lock' src="./assets/icons/lock.png" alt="lock"></img>
                </>
                
            }
            
        </div>
    );
}

export default LeguageBanner;