import { FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { NavLink } from "react-router-dom";

export const NavigationMenu = ({ isActive, setIsActive }) => {
    const linkRefs = useRef([]);
    const [linkWidth, setLinkWidth] = useState(0);
    const [linkLeft, setLinkLeft] = useState(0);
    const [isTrans, setIrans] = useState('');

    const handleClick = (index) => {
        setIsActive(index);
        const link = linkRefs.current[index];
        if (link) {
            const width = link.getBoundingClientRect().width; 
            setLinkWidth(width); 
            const left = link.getBoundingClientRect().left;
            setLinkLeft(left);
            console.log(`Ширина текста: ${width}px`); 
        }
    };
    useEffect(() => {
        handleClick(2);
        setTimeout(() => {
            setIrans('all 0.3s ease');
        }, 100)
    }, [])

    return (
        <nav className={styles.container}>
            <div className={styles.menu} >
                <div className={styles.menuWrapper}>
                    <div className={styles.cloud} style={{width:`${linkWidth + 15}px`, left:`${linkLeft - 15}px`, transition: isTrans}}></div>
                    <NavLink ref={(el) => (linkRefs.current[0] = el)} className={`${styles.item} ${styles.disabled}`} to="#" onClick={() => handleClick(0)}>
                        shop
                    </NavLink>
                    <NavLink ref={(el) => (linkRefs.current[1] = el)} className={styles.item} to="/tasks" onClick={() => handleClick(1)}>
                        tasks
                    </NavLink>
                    <NavLink ref={(el) => (linkRefs.current[2] = el)} className={styles.item} to="/" onClick={() => handleClick(2)}>
                        home
                    </NavLink>
                    <NavLink ref={(el) => (linkRefs.current[3] = el)} className={styles.item} to="/invites" onClick={() => handleClick(3)}>
                        invites
                    </NavLink>
                    <NavLink ref={(el) => (linkRefs.current[4] = el)} className={`${styles.item} ${styles.disabled}`} to="#" onClick={() => handleClick(4)}>
                        soon
                    </NavLink>
                </div>
            </div>
        </nav>
    );
}

export default NavigationMenu;
