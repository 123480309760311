import React, { useState, useEffect } from "react";
import back from '../assets/sprites/test.png'

const CachedImages = ({ isLoading, setIsLoading, setShowApp }) => {
  const [progress, setProgress] = useState(0); // Отображение прогресса



  const imagesToCache = [
    "/assets/sprites/test.png",
    "/assets/sprites/Ball.png",
    "/assets/sprites/Coin2.png",
    "/assets/images/image1.jpg",
    "/assets/images/image2.jpg",
    "/assets/images/test2.jpg",
    back,
  ];

  // Функция для предзагрузки изображений
  const preloadImages = (imageArray) => {
    let loadedImagesCount = 0;

    return new Promise((resolve, reject) => {
      imageArray.forEach((src, index) => {
        const img = new Image();
        img.src = src;

        img.onload = () => {
          loadedImagesCount++;
          setProgress((prevProgress) => prevProgress + (100 / imageArray.length));

          if (loadedImagesCount === imageArray.length) {
            resolve(); // Все изображения загружены
          }
        };

        img.onerror = (error) => {
          console.error(`Ошибка загрузки изображения: ${src}`, error);
          reject(error);
        };
      });
    });
  };

  useEffect(() => {
    setIsLoading(true); // Устанавливаем состояние загрузки в начале

    preloadImages(imagesToCache)
      .then(() => {
        setShowApp(true); // Показываем приложение после загрузки всех изображений
        setIsLoading(false); // Останавливаем индикацию загрузки
      })
      .catch((error) => {
        console.error("Ошибка загрузки:", error);
        setIsLoading(false); // Даже при ошибке завершить процесс загрузки
      });
  }, [setIsLoading, setShowApp]);

  return (
    <>
      {isLoading && (
        <div>
          <p>Загрузка... {Math.round(progress)}%</p>
        </div>
      )}
    </>
  );
};

export default CachedImages;
