import React, { useState, useEffect } from 'react';
import { Image, Text } from 'react-konva';
import useImage from 'use-image';

const CoinCounter = React.forwardRef(({ coins }, ref) => {
  const [coinImage] = useImage('./assets/images/Coin.png'); // Замените путь на ваш

  return (
    <React.Fragment>
        <>
          <Image
            image={coinImage}
            x={window.innerWidth * 0.85 - 45}
            y={10}
            width={40}
            height={40}
          />
          <Text
            ref={ref}
            text={coins}
            x={window.innerWidth * 0.85}
            y={20}
            fontSize={24}
            fill="white"
            fontFamily='MonBold'
          />
        </>
    </React.Fragment>
  );
});

export default CoinCounter;
