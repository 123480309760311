import { useContext } from "react";
import {$host, $authHost} from "./index.js"
import { jwtDecode } from "jwt-decode";
import { Context } from "../index.js";

console.log(44, process.env.REACT_APP_API_URL);
//const path = "http://localhost:49547";
const path = 'https://sph.telegram-ka.ru'

export const login = async (id, tgData) => {
  try {
    const {data} = await $host.post(`${path}/api/user/login`, {
      id: id,
      telegramInitData: tgData
      
    }, {
      headers: {
        'Content-Type': 'application/json'
    }

    });

  return jwtDecode(data.token);
  }
  catch(e) {
    console.log(e);
  }
    
}

export const updateUser = async (isTimeReset, coins, id) => {
  try {
    const response = await $host.post(`${path}/api/user/update`, {
      isTimeReset: isTimeReset, 
      coins: coins,
      id: id,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
    return response;
  }
  catch(e) {
    console.log(e);
  }
 
}



export const users = async (id, task_id, platform) => {
  const {data} = await $host.get(`${path}/api/user/users`);
  return data;
}

export const leaders = async () => {
  const {data} = await $host.get(`${path}/api/user/leaders`);
  return data;
}

export const refs = async (id) => {
  const {data} = await $host.get(`${path}/api/referral/all?id=${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
    }
    }
  );
  return data;
}

export const updateAction = async (id) => {
  const data = await $host.post(`${path}/api/user/updateaction`, {
    id: id
  }, {
    headers: {
      'Content-Type': 'application/json',
    
    }
  }
);
  return data;
}


export const getTasks = async(id) => {
  const data = await $host.get(`${path}/api/userTask/all?userId=${id}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return data
}

export const setCoinsUser = async(id, coins) => {
  const data = await $host.post(`${path}/api/user/setCoins`, {
    id: id,
    coins: coins
  }, {
    headers: {
      'Content-Type': 'application/json',
    
    }
  });
  return data;
}

export const clearClaimRefferal = async(id, coins) => {
  const data = await $host.post(`${path}/api/referral/clearclaim`, {
    id: id,
    coins: coins
  }, {
    headers: {
      'Content-Type': 'application/json',
    
    }
  })
  return data;
}

export const getLeguage = async(leaguage) => {
  const data = await $host.post(`${path}/api/user/leguage`, {
    leaguage:leaguage
  },
{
  headers: {
    'Content-Type': 'application/json',
  
  }
})
return data;
}

export const setClaimDays = async(id, days) => {
  const data = await $host.post(`${path}/api/user/claimdays`, {
    days:days,
    id:id
  },
{
  headers: {
    'Content-Type': 'application/json',
  
  }
})
return data;
}

export const clearClaim = async(id, days) => {
  const data = await $host.post(`${path}/api/user/clearaction`, {

    id:id
  },
{
  headers: {
    'Content-Type': 'application/json',
  
  }
})
return data;
}

export const doneTask = async(id, taskId, status, prise) => {
  const data = await $host.post(`${path}/api/usertask/done`, {
    userId:id,
    taskId:taskId,
    status:status,
    prise:prise
  },
{
  headers: {
    'Content-Type': 'application/json',
  
  }
})
return data;
}



///api/usertask/all?userId=1